import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { destroy, isDirty } from 'redux-form';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { Checkbox, Typography, Link, Button } from '@mui/material';

import { post, get, update } from 'api';
import { Loader, ConfirmBackActionPopup } from 'components';
import ZeroSectionForm from 'components/sds-authoring-forms-v2/section-0-form';
import HazardsCodesForm from 'components/sds-authoring-forms-v2/hazards-codes-form';
import ProductDescriptionForm from 'components/sds-authoring-forms-v2/product-description-form';
import IngredientsForm from 'components/sds-authoring-forms-v2/ingredients-form';
import FirstAidForm from 'components/sds-authoring-forms-v2/first-aid';
import ExposureProtectionForm from 'components/sds-authoring-forms-v2/exposure-protection-form';
import StorageInfoForm from 'components/sds-authoring-forms-v2/storage-info-form';
import AccidentalReleaseForm from 'components/sds-authoring-forms-v2/accidental-release-form';
import FireFightingForm from 'components/sds-authoring-forms-v2/firefighting-form';
import PreviewPDFFile from 'components/sds-authoring-forms-v2/preview-pdf-file';
import OtherInfoForm from 'components/sds-authoring-forms-v2/other-info-form';
import RegulatoryForm from 'components/sds-authoring-forms-v2/regulatory-form';
import TransportRequirementsForm from 'components/sds-authoring-forms-v2/transport-form';
import DisposalForm from 'components/sds-authoring-forms-v2/disposal-form';
import EcologicalForm from 'components/sds-authoring-forms-v2/ecological-form';
import ToxicologicalForm from 'components/sds-authoring-forms-v2/toxicological-form';
import StabilityAndReactivityForm from 'components/sds-authoring-forms-v2/stability-reactivity-form';
import PhysicalAndChemicalPropertiesForm from 'components/sds-authoring-forms-v2/physical-and-chemical-properties-form';
import {
  setPhysChemProperties,
  setSDSId,
  setSDSInfo,
  setTranslations,
  addCompletedAuthoringStep,
  setShowConfirmBackDialog,
  setComponentStructuredData,
  clearCompletedSteps,
  setFormEdited,
  setAdditionalPhysChemProperties,
  setCountriesSettings,
  setIsSubstanceSDS,
} from 'reducers/pageAction';
import CustomStepper from 'components/sds-authoring/stepper';
import useStyles from './styles';
import { isMobile } from 'utils/helper';
import MobileStepper from 'components/sds-authoring/mobile-stepper';
import RestoreDataPopup from 'components/sds-authoring/restore-data-popup';

interface SDSAuthoringPageProps {
  state: any;
  productDescriptionForm: any;
  ingredientsForm: any;
  physicalAndChemicalPropertiesForm: any;
  hazardsCodesForm: any;
  accidentalReleaseForm: any;
  disposalForm: any;
  ecologicalForm: any;
  exposureProtectionForm: any;
  fireFightingForm: any;
  firstAidForm: any;
  otherInfoForm: any;
  regulationsForm: any;
  stabilityAndReactivityForm: any;
  storageInfoForm: any;
  toxicologicalForm: any;
  transportRequirementsForm: any;
  section0Form: any;
}

const SDSAuthoringPage = ({
  state,
  productDescriptionForm,
  ingredientsForm,
  physicalAndChemicalPropertiesForm,
  hazardsCodesForm,
  accidentalReleaseForm,
  disposalForm,
  ecologicalForm,
  exposureProtectionForm,
  fireFightingForm,
  firstAidForm,
  otherInfoForm,
  regulationsForm,
  stabilityAndReactivityForm,
  storageInfoForm,
  toxicologicalForm,
  transportRequirementsForm,
  section0Form,
}: SDSAuthoringPageProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['sds_authoring', 'steps']);
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();
  const location: { state: { completedSteps: Array<number> } } = useLocation();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const [showSectionsData, setShowSectionsData] = useState(false);
  const [openRestorePopup, setOpenRestorePopup] = React.useState(false);
  const [shouldUpdateData, setShouldUpdateData] = React.useState(false);
  const sdsId = useSelector((state: any) => state.pageReducer.pdfId);
  const languageCode = useSelector(
    (state: any) => state.pageReducer.languageCode
  );
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const overwrittenHazardStatements = useSelector(
    (state: any) => state.pageReducer.overwrittenHazardStatements
  );
  const openConfirmBackPopup = useSelector(
    (state: any) => state.pageReducer.showConfirmBackDialog
  );
  const completedSteps = useSelector(
    (state: any) => state.pageReducer.completedAuthoringStep
  );
  const sdsInfo = useSelector((state: any) => state.pageReducer.sdsInfo);

  const doNotIncludeStructuredDataFromComponents = useSelector(
    (state: any) => state.pageReducer.doNotIncludeStructuredDataFromComponents
  );
  const structuredData = useSelector(
    (state: any) => state.pageReducer.structuredComponentData
  );

  const interval: { current: NodeJS.Timeout | null } = React.useRef(null);

  const autoSaveKey = params.sdsId
    ? `form_data_${params.sdsId}`
    : 'form_data_none_sdsId';

  const createSDS = () => {
    const hazardCodesIds = hazardsCodesForm?.values?.hazardCodes?.map(
      (el: any) => el.id
    );
    const regulatoryIds = regulationsForm?.values?.regulations?.map(
      (el: any) => el.id
    );
    const respiratoryProtectionIds =
      exposureProtectionForm?.values?.respiratoryProtection?.length > 0
        ? exposureProtectionForm?.values?.respiratoryProtection?.map(
            (el: any) => el.id
          )
        : sdsInfo?.respiratory_protection?.map((el: any) => el.id);
    const eyesProtectionIds =
      exposureProtectionForm?.values?.eyesProtection?.length > 0
        ? exposureProtectionForm?.values?.eyesProtection?.map(
            (el: any) => el.id
          )
        : sdsInfo?.eyes_protection?.map((el: any) => el.id);
    const handsProtectionIds =
      exposureProtectionForm?.values?.handsProtection?.length > 0
        ? exposureProtectionForm?.values?.handsProtection?.map(
            (el: any) => el.id
          )
        : sdsInfo?.hands_protection?.map((el: any) => el.id);
    const bodyProtectionIds =
      exposureProtectionForm?.values?.bodyProtection?.length > 0
        ? exposureProtectionForm?.values?.bodyProtection?.map(
            (el: any) => el.id
          )
        : sdsInfo?.body_protection?.map((el: any) => el.id);

    const components = ingredientsForm?.values?.ingredients
      ? ingredientsForm?.values?.ingredients.map((el: any) => {
          return {
            component_sds: el.component_sds,
            concentration: el.concentration,
            component_name: el.component_name,
            cas_no: el.cas_no,
            reach_no: el.reach_no,
            hazard_abbreviation: el.hazard_abbreviation,
          };
        })
      : sdsInfo?.components?.map((el: any) => {
          return {
            component_sds: el.component_sds,
            concentration: el.concentration,
            component_name: el.component_name,
            cas_no: el.cas_no,
            reach_no: el.reach_no,
            hazard_abbreviation: el.hazard_abbreviation,
          };
        });

    const sdsData = {
      ...section0Form?.values,
      ...productDescriptionForm?.values,
      ...accidentalReleaseForm?.values,
      ...disposalForm?.values,
      ...ecologicalForm?.values,
      ...fireFightingForm?.values,
      ...firstAidForm?.values,
      ...otherInfoForm?.values,
      ...stabilityAndReactivityForm?.values,
      ...storageInfoForm?.values,
      ...toxicologicalForm?.values,
      ...exposureProtectionForm?.values,
      physical_chemical_properties: physicalAndChemicalPropertiesForm?.values,
      transport_requirements: transportRequirementsForm?.values
        ? transportRequirementsForm.values
        : sdsInfo?.transport_requirements,
      components_input: components,
      hazards_information: {
        ...hazardsCodesForm?.values,
        overwritten_statements: overwrittenHazardStatements,
        codes_input: hazardCodesIds
          ? hazardCodesIds.filter((el: any) => !!el)
          : sdsInfo?.hazards_information?.codes?.map((el: any) => el.id),
      },
      regulations_input: regulatoryIds
        ? regulatoryIds.filter((el: any) => !!el)
        : sdsInfo?.regulations.map((el: any) => el.id),
      body_protection_input: bodyProtectionIds,
      hands_protection_input: handsProtectionIds,
      eyes_protection_input: eyesProtectionIds,
      respiratory_protection_input: respiratoryProtectionIds,
    };
    const createSDSRequest = post('/sdsAuthoring/files/', sdsData);
    createSDSRequest.then((response) => {
      if (response?.status === 201) {
        localStorage.removeItem(`form_data_${sdsId}`);
        setShowError(false);
        dispatch(setSDSInfo(response.data));
        dispatch(setSDSId(response.data.id));
        dispatch(setIsSubstanceSDS(response.data.is_substance_sds));
        response.data?.components?.forEach((component: any) =>
          dispatch(
            setComponentStructuredData({
              [component.id]: component.structured_data,
            })
          )
        );
        history.push(`/sds-authoring/${response.data.id}`, {
          completedSteps: [0, 1],
        });
      } else {
        setShowError(true);
      }
    });
  };

  const updateSDS = (data: any) => {
    if (sdsId) {
      const updateSDSRequest = update(`/sdsAuthoring/files/${sdsId}/`, data);
      updateSDSRequest.then((response) => {
        if (response?.status === 200) {
          dispatch(addCompletedAuthoringStep(activeStep));
          dispatch(setSDSInfo(response.data));
          response.data?.components?.forEach((component: any) =>
            dispatch(
              setComponentStructuredData({
                [component.id]: component.structured_data,
              })
            )
          );
          setActiveStep(activeStep + 1);
        }
      });
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const getFormByStep = (step: number): any => {
    switch (step) {
      case 0:
        return { name: 'section0Form', form: section0Form };
      case 1:
        return { name: 'productDescriptionForm', form: productDescriptionForm };
      case 2:
        return { name: 'hazardsCodesForm', form: hazardsCodesForm };
      case 3:
        return { name: 'ingredientsForm', form: ingredientsForm };
      case 4:
        return { name: 'firstAidForm', form: firstAidForm };
      case 5:
        return { name: 'fireFightingForm', form: fireFightingForm };
      case 6:
        return { name: 'accidentalReleaseForm', form: accidentalReleaseForm };
      case 7:
        return { name: 'storageInfoForm', form: storageInfoForm };
      case 8:
        return { name: 'exposureProtectionForm', form: exposureProtectionForm };
      case 9:
        return {
          name: 'physicalAndChemicalPropertiesForm',
          form: physicalAndChemicalPropertiesForm,
        };
      case 10:
        return {
          name: 'stabilityAndReactivityForm',
          form: stabilityAndReactivityForm,
        };
      case 11:
        return { name: 'toxicologicalForm', form: toxicologicalForm };
      case 12:
        return { name: 'ecologicalForm', form: ecologicalForm };
      case 13:
        return { name: 'disposalForm', form: disposalForm };
      case 14:
        return {
          name: 'transportRequirementsForm',
          form: transportRequirementsForm,
        };
      case 15:
        return { name: 'regulationsForm', form: regulationsForm };
      case 16:
        return { name: 'otherInfoForm', form: otherInfoForm };
      default:
        return {};
    }
  };

  const getPhysicalChemicalPropertiesFields = () => {
    setLoading(true);
    const getPhysicalChemicalPropertiesFieldsRequest = get(
      '/sdsAuthoring/utils/physChemProperties/',
      { language_code: languageCode, created_sds_id: params.sdsId }
    );
    getPhysicalChemicalPropertiesFieldsRequest.then((response) => {
      if (response?.status === 200) {
        dispatch(setPhysChemProperties(response.data.default_tags));
        dispatch(
          setAdditionalPhysChemProperties(response.data.additional_tags)
        );
        setLoading(false);
      }
    });
  };

  const getTranslations = () => {
    setLoading(true);
    const getTranslationsRequest = get('/sdsAuthoring/utils/translations', {
      language_code: languageCode,
    });
    getTranslationsRequest.then((response) => {
      if (response?.status === 200) {
        dispatch(setTranslations(response.data));
        setLoading(false);
      }
    });
  };

  const getCountriesSettings = () => {
    setLoading(true);
    const getCountriesSettingsRequest = get(
      '/sdsAuthoring/utils/defaultCountriesSettings/',
      { languageCode: languageCode }
    );
    getCountriesSettingsRequest.then((response) => {
      if (response?.status === 200) {
        setLoading(false);
        dispatch(setCountriesSettings(response.data));
      }
    });
  };

  const handleNext = (data: any) => {
    dispatch(setFormEdited(false));
    dispatch(addCompletedAuthoringStep(activeStep));
    if (!data) {
      dispatch(addCompletedAuthoringStep(activeStep));
      return setActiveStep(activeStep + 1);
    }

    if (activeStep === 1 && !sdsId) {
      createSDS();
      return setActiveStep(activeStep + 1);
    }

    return updateSDS(data);
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(setSDSInfo(null));
    dispatch(setSDSId(params.sdsId ?? null));
    dispatch(clearCompletedSteps());
    dispatch(destroy('productDescriptionForm'));
    dispatch(destroy('ingredientsForm'));
    dispatch(destroy('physicalAndChemicalPropertiesForm'));
    dispatch(destroy('hazardsCodesForm'));
    dispatch(destroy('accidentalReleaseForm'));
    dispatch(destroy('disposalForm'));
    dispatch(destroy('ecologicalForm'));
    dispatch(destroy('fireFightingForm'));
    dispatch(destroy('firstAidForm'));
    dispatch(destroy('otherInfoForm'));
    dispatch(destroy('regulationsForm'));
    dispatch(destroy('stabilityAndReactivityForm'));
    dispatch(destroy('toxicologicalForm'));
    dispatch(destroy('euTransportForm'));
    dispatch(destroy('usTransportForm'));
    dispatch(destroy('storageInfoForm'));
    dispatch(destroy('section0Form'));
    dispatch(destroy('transportRequirementsForm'));
    if (localStorage.getItem(autoSaveKey)) {
      return setOpenRestorePopup(true);
    }
    if (!params.sdsId) {
      return setShouldUpdateData(true);
    }

    if (params.sdsId) {
      const getSDSInfoRequest = get(`sdsAuthoring/files/${params.sdsId}/`, {});
      getSDSInfoRequest.then((response) => {
        if (response?.status === 200) {
          dispatch(setSDSInfo(response.data));
          dispatch(setIsSubstanceSDS(response.data.is_substance_sds));
          response.data?.components?.forEach((component: any) =>
            dispatch(
              setComponentStructuredData({
                [component.id]: component.structured_data,
              })
            )
          );
          setShouldUpdateData(true);
        }
      });
    }
    setLoading(false);
  }, [params.sdsId]);

  React.useEffect(() => {
    getTranslations();
    getPhysicalChemicalPropertiesFields();
    getCountriesSettings();
  }, [languageCode]);

  React.useEffect(() => {
    location.state?.completedSteps?.map((el: number) => {
      dispatch(addCompletedAuthoringStep(el));
    });
  }, [location.state]);

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <ZeroSectionForm handleNext={handleNext} errors={[]} />;
      case 1:
        return (
          <ProductDescriptionForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 2:
        return (
          <HazardsCodesForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 3:
        return (
          <IngredientsForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 4:
        return (
          <FirstAidForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 5:
        return (
          <FireFightingForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 6:
        return (
          <AccidentalReleaseForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 7:
        return (
          <StorageInfoForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 8:
        return (
          <ExposureProtectionForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
          />
        );
      case 9:
        return (
          <PhysicalAndChemicalPropertiesForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
          />
        );
      case 10:
        return (
          <StabilityAndReactivityForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 11:
        return (
          <ToxicologicalForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 12:
        return (
          <EcologicalForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 13:
        return (
          <DisposalForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 14:
        return (
          <TransportRequirementsForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 15:
        return (
          <RegulatoryForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 16:
        return (
          <OtherInfoForm
            handleNext={handleNext}
            handleCancel={() => setActiveStep(activeStep - 1)}
            errors={[]}
          />
        );
      case 17:
        return <PreviewPDFFile pdfFileId={sdsId} />;
      default:
        return <></>;
    }
  };

  const haveFormsNotSaved = () => {
    for (let i = 0; i <= 16; i++) {
      const form = getFormByStep(i);
      // if form is dirty and not saved then return true
      if (isDirty(form.name)(state) && !completedSteps.includes(i)) {
        return true;
      }
    }
    return false;
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleUnload);
    };
  }, [state.form]);

  const alertUser = (e: any) => {
    if (haveFormsNotSaved()) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const handleUnload = () => {
    localStorage.removeItem(autoSaveKey);
  };

  const getData = () => {
    const regulatoryIds = regulationsForm?.values?.regulations?.map(
      (el: any) => el.id
    );
    const respiratoryProtectionIds =
      exposureProtectionForm?.values?.respiratoryProtection?.length > 0
        ? exposureProtectionForm?.values?.respiratoryProtection?.map(
            (el: any) => el.id
          )
        : sdsInfo?.respiratory_protection?.map((el: any) => el.id);
    const eyesProtectionIds =
      exposureProtectionForm?.values?.eyesProtection?.length > 0
        ? exposureProtectionForm?.values?.eyesProtection?.map(
            (el: any) => el.id
          )
        : sdsInfo?.eyes_protection?.map((el: any) => el.id);
    const handsProtectionIds =
      exposureProtectionForm?.values?.handsProtection?.length > 0
        ? exposureProtectionForm?.values?.handsProtection?.map(
            (el: any) => el.id
          )
        : sdsInfo?.hands_protection?.map((el: any) => el.id);
    const bodyProtectionIds =
      exposureProtectionForm?.values?.bodyProtection?.length > 0
        ? exposureProtectionForm?.values?.bodyProtection?.map(
            (el: any) => el.id
          )
        : sdsInfo?.body_protection?.map((el: any) => el.id);

    const components = ingredientsForm?.values?.ingredients
      ? ingredientsForm?.values?.ingredients.map((el: any) => {
          return el;
        })
      : sdsInfo?.components?.map((el: any) => {
          return el;
        });
    components?.forEach((component: any) => {
      const identifier = component.id ?? component.hash;
      component.structured_data =
        doNotIncludeStructuredDataFromComponents.includes(identifier)
          ? null
          : structuredData[identifier];
    });
    return {
      ...sdsInfo,
      ...section0Form?.values,
      ...productDescriptionForm?.values,
      ...accidentalReleaseForm?.values,
      ...disposalForm?.values,
      ...ecologicalForm?.values,
      ...fireFightingForm?.values,
      ...firstAidForm?.values,
      ...otherInfoForm?.values,
      ...stabilityAndReactivityForm?.values,
      ...storageInfoForm?.values,
      ...toxicologicalForm?.values,
      ...exposureProtectionForm?.values,
      physical_chemical_properties:
        physicalAndChemicalPropertiesForm?.values ??
        sdsInfo?.physical_chemical_properties,
      transport_requirements:
        transportRequirementsForm?.values ?? sdsInfo?.transport_requirements,
      components: components,
      hazards_information: {
        ...sdsInfo?.hazards_information,
        ...hazardsCodesForm?.values,
        precautions_codes: hazardsCodesForm?.values.precautionsCodes,
        codes: hazardsCodesForm?.values.hazardCodes,
        overwritten_statements: overwrittenHazardStatements,
      },
      regulations_input: regulatoryIds
        ? regulatoryIds.filter((el: any) => !!el)
        : sdsInfo?.regulations?.map((el: any) => el.id),
      body_protection_input: bodyProtectionIds,
      hands_protection_input: handsProtectionIds,
      eyes_protection_input: eyesProtectionIds,
      respiratory_protection_input: respiratoryProtectionIds,
    };
  };

  const [updateTrigger, setUpdateTrigger] = React.useState(0);

  React.useEffect(() => {
    if (!shouldUpdateData) {
      return;
    }
    let result = getData();
    localStorage.setItem(autoSaveKey, JSON.stringify(result));
  }, [updateTrigger]);

  React.useEffect(() => {
    interval.current = setInterval(() => {
      return setUpdateTrigger((update) => update + 1);
    }, 60000);

    return () => clearTimeout(interval.current as NodeJS.Timeout);
  }, []);

  const handleCancelRestore = () => {
    if (params.sdsId) {
      const getSDSInfoRequest = get(`sdsAuthoring/files/${params.sdsId}/`, {});
      getSDSInfoRequest.then((response) => {
        if (response?.status === 200) {
          dispatch(setSDSInfo(response.data));
          response.data?.components?.forEach((component: any) =>
            dispatch(
              setComponentStructuredData({
                [component.id]: component.structured_data,
              })
            )
          );
          localStorage.removeItem(autoSaveKey);
          setLoading(false);
        }
      });
    }
    setOpenRestorePopup(false);
    setShouldUpdateData(true);
  };

  const handleSubmitRestore = () => {
    try {
      const localStorageData = JSON.parse(
        localStorage.getItem(autoSaveKey) || '{}'
      );

      if (localStorageData && typeof localStorageData === 'object') {
        dispatch(setSDSInfo(localStorageData));
        localStorageData.components?.forEach((component: any) =>
          dispatch(
            setComponentStructuredData({
              [component.id]: component.structured_data,
            })
          )
        );
        localStorage.removeItem(autoSaveKey);
        setOpenRestorePopup(false);
        setLoading(false);
        setShouldUpdateData(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  return (
    <div>
      {openRestorePopup && (
        <RestoreDataPopup
          onClose={handleCancelRestore}
          onSubmit={handleSubmitRestore}
          open={openRestorePopup}
        />
      )}
      {loading ||
      !translations ||
      (params.sdsId && !sdsInfo) ||
      openRestorePopup ? (
        <Loader />
      ) : (
        <div className={classes.container}>
          {isMobile() ? (
            <MobileStepper
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          ) : (
            <CustomStepper
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}

          <div className={classes.content}>
            {showError ? (
              <Typography color="colors.red">Something went wrong.</Typography>
            ) : (
              ''
            )}
            {sdsInfo?.source_sds_view_url && (
              <Link
                target={'_blank'}
                href={sdsInfo.source_sds_view_url}
                underline="none"
              >
                <Button sx={{ ml: 1, mb: 1 }} variant="outlined">
                  {t('sds_authoring:open_source_pdf')}
                </Button>
              </Link>
            )}
            {sdsInfo?.status === 'approved' && (
              <Alert
                severity={'info'}
                style={{
                  width: '100%',
                  boxSizing: 'border-box',
                }}
              >
                <AlertTitle>
                  {t('sds_authoring:approved_sds_message')}
                </AlertTitle>
              </Alert>
            )}
            {sdsInfo?.imported_sds && (
              <div style={{}}>
                <div className={classes.checkboxWrapper}>
                  <Checkbox
                    color={'primary'}
                    onClick={() => setShowSectionsData(!showSectionsData)}
                    checked={showSectionsData}
                  />
                  <Typography>
                    {t('sds_authoring:show_imported_sds_data')}
                  </Typography>
                </div>
                {showSectionsData && (
                  <div
                    className={classes.reviewDataRightSideContent}
                    dangerouslySetInnerHTML={{
                      __html: sdsInfo?.html_sections[activeStep],
                    }}
                  />
                )}
              </div>
            )}

            <div className={classes.stepFormWrapper}>
              {getStepContent(activeStep)}
            </div>
          </div>
        </div>
      )}
      {openConfirmBackPopup && (
        <ConfirmBackActionPopup
          onClose={() => dispatch(setShowConfirmBackDialog(false))}
          onSubmit={() => {
            dispatch(setShowConfirmBackDialog(false));
            history.push('/');
          }}
          open={openConfirmBackPopup}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    state: state,
    section0Form: state.form.section0Form,
    productDescriptionForm: state.form.productDescriptionForm,
    ingredientsForm: state.form.ingredientsForm,
    physicalAndChemicalPropertiesForm:
      state.form.physicalAndChemicalPropertiesForm,
    hazardsCodesForm: state.form.hazardsCodesForm,
    accidentalReleaseForm: state.form.accidentalReleaseForm,
    disposalForm: state.form.disposalForm,
    ecologicalForm: state.form.ecologicalForm,
    exposureProtectionForm: state.form.exposureProtectionForm,
    fireFightingForm: state.form.fireFightingForm,
    firstAidForm: state.form.firstAidForm,
    otherInfoForm: state.form.otherInfoForm,
    regulationsForm: state.form.regulationsForm,
    stabilityAndReactivityForm: state.form.stabilityAndReactivityForm,
    storageInfoForm: state.form.storageInfoForm,
    toxicologicalForm: state.form.toxicologicalForm,
    transportRequirementsForm: state.form.transportRequirementsForm,
  };
};
export default connect(mapStateToProps)(SDSAuthoringPage);
