import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { connect, useSelector } from 'react-redux';
import useStyles from '../styles';
import { Grid } from '@mui/material';
import TextFieldInputV2 from 'components/text-field';
import SubmitButton from '../submit-btn';
import OutlinedSection from '../outlined-section';
import { CustomFormProps } from '../utils';

const DisposalForm = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation(['fields']);

  const { handleNext, dirty } = props;
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector((state: any) => state.form.disposalForm);

  const handleSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <OutlinedSection
        variant="h5"
        title={translations.headers['13_header_disposal_consideration']}
      >
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Field
            name="product_disposal"
            component={TextFieldInputV2}
            label={
              translations.sections.product_disposal
                ? translations.sections.product_disposal
                : t('fields:product_disposal')
            }
            classes={{ root: classes.textInput }}
            multiline={true}
          />
          <Field
            name="packaging_disposal"
            component={TextFieldInputV2}
            label={
              translations.sections.packaging_disposal
                ? translations.sections.packaging_disposal
                : t('fields:packaging_disposal')
            }
            classes={{ root: classes.textInput }}
            multiline={true}
          />
          <Field
            name="waste_treatment"
            component={TextFieldInputV2}
            label={
              translations.sections.waste_treatment
                ? translations.sections.waste_treatment
                : t('fields:waste_treatment')
            }
            classes={{ root: classes.textInput }}
            multiline={true}
          />
          <Field
            name="sewage_disposal"
            component={TextFieldInputV2}
            label={
              translations.sections.sewage_disposal
                ? translations.sections.sewage_disposal
                : t('fields:sewage_disposal')
            }
            classes={{ root: classes.textInput }}
            multiline={true}
          />
          <Field
            name="additional_disposal_info"
            component={TextFieldInputV2}
            label={
              translations.sections.additional_disposal_info
                ? translations.sections.additional_disposal_info
                : t('fields:additional_disposal_info')
            }
            classes={{ root: classes.textInput }}
            multiline={true}
          />
        </Grid>
      </OutlinedSection>
      <form onSubmit={handleSubmit}>
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.disposalForm?.values) {
    return {
      initialValues: {
        product_disposal: state.pageReducer.sdsInfo.product_disposal,
        packaging_disposal: state.pageReducer.sdsInfo.packaging_disposal,
        waste_treatment: state.pageReducer.sdsInfo.waste_treatment,
        sewage_disposal: state.pageReducer.sdsInfo.sewage_disposal,
        additional_disposal_info:
          state.pageReducer.sdsInfo.additional_disposal_info,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'disposalForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(DisposalForm)
);
