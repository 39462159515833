import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Grid } from '@mui/material';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { getStep10Fields } from './fields';
import OutlinedSection from '../outlined-section';
import { CustomFormProps } from '../utils';

const StabilityAndReactivityForm = (props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>) => {
  const classes = useStyles();
  const { handleNext, dirty } = props;
  const translations = useSelector((state: any) => state.pageReducer.translations);
  const form = useSelector((state: any) => state.form.stabilityAndReactivityForm);

  const fields = getStep10Fields();

  const handleSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <form onSubmit={handleSubmit}>
        <OutlinedSection
          variant="h5"
          title={translations.headers['10_header_stability_reactivity']}
        >
          {fields.map((field, idx) => {
            return (
              <Grid key={idx} item xs={field.xs}>
                <Field
                  validate={field.validate ?? null}
                  name={field.name}
                  component={field.component}
                  label={field.label}
                  multiline={field.multiline ?? null}
                  options={field.options ?? null}
                  required={field.required ?? null}
                  valueField={field.valueField ?? null}
                  labelField={field.labelField ?? null}
                  classes={{ root: classes.textInput }}
                />
              </Grid>
            );
          })}
        </OutlinedSection>
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.regulationsForm?.values) {
    return {
      initialValues: {
        reactivity: state.pageReducer.sdsInfo.reactivity,
        stability: state.pageReducer.sdsInfo.stability,
        possibility_of_hazardous_reactions:
          state.pageReducer.sdsInfo.possibility_of_hazardous_reactions,
        conditions_to_avoid: state.pageReducer.sdsInfo.conditions_to_avoid,
        incompatible_materials:
          state.pageReducer.sdsInfo.incompatible_materials,
        hazardous_decomposition_products:
          state.pageReducer.sdsInfo.hazardous_decomposition_products,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'stabilityAndReactivityForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(StabilityAndReactivityForm)
);
