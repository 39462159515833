import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Grid, Typography } from '@mui/material';
import TextFieldInputV2 from 'components/text-field';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import OutlinedSection from '../outlined-section';
import { CustomFormProps } from '../utils';

const OtherInfoForm = (props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>) => {
  const classes = useStyles();
  const { handleNext, dirty } = props;
  const { t } = useTranslation(['fields']);
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector((state: any) => state.form.otherInfoForm);

  const handleSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <form onSubmit={handleSubmit}>
        <OutlinedSection
          variant="h5"
          title={translations.headers['16_header_other_information']}
        >
          <Grid item xs={12}>
            <Field
              name="other_information"
              component={TextFieldInputV2}
              label={
                translations.sections.other_information
                  ? translations.sections.other_information
                  : t('fields:other_information')
              }
              classes={{ root: classes.textInput }}
              multiline={true}
            />
          </Grid>
        </OutlinedSection>
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.otherInfoForm?.values) {
    return {
      initialValues: {
        other_information: state.pageReducer.sdsInfo.other_information,
        disclaimer: state.pageReducer.sdsInfo.disclaimer,
        preparation_information:
          state.pageReducer.sdsInfo.preparation_information,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'otherInfoForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(OtherInfoForm)
);
