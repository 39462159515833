import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FieldArray } from 'redux-form';
import DataToTablePresentation from './DataToTablePresentation';

import { Grid, Typography } from '@mui/material';
import { get } from 'api';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { getStep13Fields } from './fields';
import { CustomFormProps } from '../utils';
import { IsoIconsField } from '../common/IsoIconsFields';

const TransportRequirementsForm = (
  props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>
) => {
  const classes = useStyles();
  const { handleNext, dirty } = props;
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector(
    (state: any) => state.form.transportRequirementsForm
  );

  const [isoIcons, setIsoIcons] = React.useState([]);

  const sections = getStep13Fields();

  React.useEffect(() => {
    const getIsoIconsRequest = get('/sdsAuthoring/utils/isoIcons/', {
      type: 'T',
    });
    getIsoIconsRequest.then((response) => {
      if (response.status === 200) {
        setIsoIcons(response.data);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (dirty) {
      handleNext({
        transport_requirements: {
          id: form.values.id,
          rid_transport_classes_icons_input: form.values.ridTransportIcons?.map(
            (el: any) => el.id
          ),
          adn_transport_classes_icons_input: form.values.adnTransportIcons?.map(
            (el: any) => el.id
          ),
          adr_transport_classes_icons_input: form.values.adrTransportIcons?.map(
            (el: any) => el.id
          ),
          iata_transport_classes_icons_input:
            form.values.iataTransportIcons?.map((el: any) => el.id),
          imdg_transport_classes_icons_input:
            form.values.imdgTransportIcons?.map((el: any) => el.id),
          imdg: {
            un_number: form.values.imdg_un_number,
            shipping_name: form.values.imdg_shipping_name,
            transport_hazard_classes: form.values.imdg_transport_hazard_classes,
            packing_group: form.values.imdg_packing_group,
          },
          iata: {
            un_number: form.values.iata_un_number,
            shipping_name: form.values.iata_shipping_name,
            transport_hazard_classes: form.values.iata_transport_hazard_classes,
            packing_group: form.values.iata_packing_group,
          },
          adr: {
            un_number: form.values.adr_un_number,
            shipping_name: form.values.adr_shipping_name,
            transport_hazard_classes: form.values.adr_transport_hazard_classes,
            packing_group: form.values.adr_packing_group,
          },
          rid: {
            un_number: form.values.rid_un_number,
            shipping_name: form.values.rid_shipping_name,
            transport_hazard_classes: form.values.rid_transport_hazard_classes,
            packing_group: form.values.rid_packing_group,
          },
          adn: {
            un_number: form.values.adn_un_number,
            shipping_name: form.values.adn_shipping_name,
            transport_hazard_classes: form.values.adn_transport_hazard_classes,
            packing_group: form.values.adn_packing_group,
          },
          environmental_hazards: form.values.environmental_hazards,
          special_precautions_for_user:
            form.values.special_precautions_for_user,
          transport_in_bulk: form.values.transport_in_bulk,
          other_transport_information: form.values.other_transport_information,
        },
      });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['14_header_transport_info']}
      </Typography>

      <DataToTablePresentation data={form.values.sds_transport_info} />

      <form onSubmit={handleSubmit}>
        {sections.map((section, index) => {
          return (
            <OutlinedSection key={index} title={section.title}>
              {section.fields.map((field, idx) => {
                return (
                  <Grid key={idx} item md={field.xs} xs={12}>
                    {field.isArray ? (
                      <FieldArray
                        name={field.name}
                        component={IsoIconsField}
                        availableIcons={isoIcons}
                      />
                    ) : (
                      <Field
                        name={field.name}
                        component={field.component ?? null}
                        label={field.label}
                        multiline={field.multiline ?? null}
                        classes={{ root: classes.textInput }}
                      />
                    )}
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        })}
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (
    state.pageReducer.sdsInfo &&
    !state.form.transportRequirementsForm?.values
  ) {
    return {
      initialValues: {
        id: state.pageReducer.sdsInfo?.transport_requirements.id,
        adnTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.adn_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
              .adn_transport_classes_icons
          : [],
        ridTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.rid_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
              .rid_transport_classes_icons
          : [],
        adrTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.adr_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
              .adr_transport_classes_icons
          : [],
        iataTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.iata_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
              .iata_transport_classes_icons
          : [],
        imdgTransportIcons: state.pageReducer.sdsInfo?.transport_requirements
          ?.imdg_transport_classes_icons
          ? state.pageReducer.sdsInfo.transport_requirements
              .imdg_transport_classes_icons
          : [],
        imdg_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.imdg?.un_number,
        iata_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.iata?.un_number,
        adr_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.adr?.un_number,
        rid_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.rid?.un_number,
        adn_un_number:
          state.pageReducer.sdsInfo?.transport_requirements?.adn?.un_number,
        imdg_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.imdg
            ?.shipping_name,
        iata_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.iata
            ?.shipping_name,
        adr_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.adr?.shipping_name,
        rid_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.rid?.shipping_name,
        adn_shipping_name:
          state.pageReducer.sdsInfo?.transport_requirements?.adn?.shipping_name,
        imdg_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.imdg
            ?.transport_hazard_classes,
        iata_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.iata
            ?.transport_hazard_classes,
        adr_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.adr
            ?.transport_hazard_classes,
        rid_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.rid
            ?.transport_hazard_classes,
        adn_transport_hazard_classes:
          state.pageReducer.sdsInfo?.transport_requirements?.adn
            ?.transport_hazard_classes,
        imdg_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.imdg
            ?.packing_group,
        iata_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.iata
            ?.packing_group,
        adr_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.adr?.packing_group,
        rid_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.rid?.packing_group,
        adn_packing_group:
          state.pageReducer.sdsInfo?.transport_requirements?.adn?.packing_group,
        environmental_hazards:
          state.pageReducer.sdsInfo?.transport_requirements
            ?.environmental_hazards,
        special_precautions_for_user:
          state.pageReducer.sdsInfo?.transport_requirements
            ?.special_precautions_for_user,
        transport_in_bulk:
          state.pageReducer.sdsInfo?.transport_requirements?.transport_in_bulk,
        other_transport_information:
          state.pageReducer.sdsInfo?.transport_requirements
            ?.other_transport_information,
        sds_transport_info: state.pageReducer.sdsInfo?.sds_transport_info,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'transportRequirementsForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(TransportRequirementsForm)
);
