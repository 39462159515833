import { Grid, Typography } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import OutlinedSection from '../outlined-section';
import { getStep5Fields } from './fields';
import { CustomFormProps } from '../utils';
import { get } from '../../../api';
import React from 'react';
import { IsoIconsField } from '../common/IsoIconsFields';

const FireFightingForm = (props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>) => {
  const classes = useStyles();
  const { handleNext, dirty } = props;
  const translations = useSelector((state: any) => state.pageReducer.translations);
  const form = useSelector((state: any) => state.form.fireFightingForm);
  const [isoIcons, setIsoIcons] = React.useState([]);

  React.useEffect(() => {
    const getIsoIconsRequest = get('/sdsAuthoring/utils/isoIcons/', {
      type: 'F',
    });
    getIsoIconsRequest.then((response) => {
      if (response.status === 200) {
        setIsoIcons(response.data);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (dirty) {
      handleNext({
        ...form.values,
        firefighting_icons_input: form.values.firefighting_icons?.map(
          (el: any) => el.id
        ),
      });
    } else {
      handleNext(null);
    }
  };

  const sections = getStep5Fields();

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['5_header_firefighting_measures']}
      </Typography>
      <form onSubmit={handleSubmit}>
        {sections.map((section: any, index: any) => {
          return (
            <OutlinedSection key={index} title={section.title}>
              {section.fields.map((field: any, idx: any) => {
                if (field.isFieldArray) {
                  return (
                    <Grid key={idx} item xs={field.xs}>
                      <FieldArray
                        name={field.name}
                        component={IsoIconsField}
                        availableIcons={isoIcons}
                      />
                    </Grid>
                  );
                }

                return (
                  <Grid key={idx} item xs={field.xs}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      options={field.options ?? null}
                      required={field.required ?? null}
                      valueField={field.valueField ?? null}
                      labelField={field.labelField ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        })}
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.fireFightingForm?.values) {
    return {
      initialValues: {
        suitable_extinguishing_media:
          state.pageReducer.sdsInfo.suitable_extinguishing_media,
        protective_actions: state.pageReducer.sdsInfo.protective_actions,
        protective_equipment: state.pageReducer.sdsInfo.protective_equipment,
        hazardous_combustion_products:
          state.pageReducer.sdsInfo.hazardous_combustion_products,
        unsuitable_extinguishing_media:
          state.pageReducer.sdsInfo.unsuitable_extinguishing_media,
        explosion_data_mechanical:
          state.pageReducer.sdsInfo.explosion_data_mechanical,
        fire_hazard: state.pageReducer.sdsInfo.fire_hazard,
        explosion_hazard: state.pageReducer.sdsInfo.explosion_hazard,
        fire_fighting_hazardous_decomposition_products:
          state.pageReducer.sdsInfo
            .fire_fighting_hazardous_decomposition_products,
        in_case_of_fire: state.pageReducer.sdsInfo.in_case_of_fire,
        in_case_of_fire_liberated:
          state.pageReducer.sdsInfo.in_case_of_fire_liberated,
        fire_fighting_reactivity:
          state.pageReducer.sdsInfo.fire_fighting_reactivity,
        specific_hazard: state.pageReducer.sdsInfo.specific_hazard,
        product_mixture_hazard: state.pageReducer.sdsInfo.specific_hazard,
        general_hazard: state.pageReducer.sdsInfo.general_hazard,
        fire_fighting_additional_info:
          state.pageReducer.sdsInfo.fire_fighting_additional_info,
        extinguishing_method: state.pageReducer.sdsInfo.extinguishing_method,
        advice_for_firefighters:
          state.pageReducer.sdsInfo.advice_for_firefighters,
        special_hazards_arising_from_substance:
          state.pageReducer.sdsInfo.special_hazards_arising_from_substance,
        firefighting_icons: state.pageReducer.sdsInfo.firefighting_icons,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'fireFightingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(FireFightingForm)
);
