import { Grid, Typography } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { getStep7Fields } from './fields';
import { CustomFormProps } from '../utils';

const StorageInfoForm = (props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>) => {
  const classes = useStyles();
  const { handleNext, dirty } = props;
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector((state: any) => state.form.storageInfoForm);

  const sections = getStep7Fields();

  const handleSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['7_header_handling_and_storage']}
      </Typography>
      <form onSubmit={handleSubmit}>
        {sections.map((section, index) => {
          return (
            <OutlinedSection key={index} title={section.title}>
              {section.fields.map((field: any, idx: any) => {
                return (
                  <Grid key={idx} item xs={field.xs}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      options={field.options ?? null}
                      required={field.required ?? null}
                      valueField={field.valueField ?? null}
                      labelField={field.labelField ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        })}
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.storageInfoForm?.values) {
    return {
      initialValues: {
        advice_safe_handling: state.pageReducer.sdsInfo.advice_safe_handling,
        technical_measures: state.pageReducer.sdsInfo.technical_measures,
        protective_measures: state.pageReducer.sdsInfo.protective_measures,
        general_occupational_hygiene:
          state.pageReducer.sdsInfo.general_occupational_hygiene,
        info_about_fire: state.pageReducer.sdsInfo.info_about_fire,
        conditions_for_safe_storage:
          state.pageReducer.sdsInfo.conditions_for_safe_storage,
        precautions_for_safe_handling:
          state.pageReducer.sdsInfo.precautions_for_safe_handling,
        storage_compability: state.pageReducer.sdsInfo.storage_compability,
        storage_conditions: state.pageReducer.sdsInfo.storage_conditions,
        storage_class: state.pageReducer.sdsInfo.storage_class,
        storage_incompatible_products:
          state.pageReducer.sdsInfo.storage_incompatible_products,
        storage_temperature_recommended:
          state.pageReducer.sdsInfo.storage_temperature_recommended,
        storage_and_containers_requirements:
          state.pageReducer.sdsInfo.storage_and_containers_requirements,
        specific_end_use: state.pageReducer.sdsInfo.specific_end_use,
        storage_additional_hazards:
          state.pageReducer.sdsInfo.storage_additional_hazards,
        storage_ventilation: state.pageReducer.sdsInfo.storage_ventilation,
        storage_precautions: state.pageReducer.sdsInfo.storage_precautions,
        storage_recommended_equipment_and_procedures:
          state.pageReducer.sdsInfo
            .storage_recommended_equipment_and_procedures,
        storage_tech_env_ergo_risk:
          state.pageReducer.sdsInfo.storage_tech_env_ergo_risk,
        storage_tech_env_risk: state.pageReducer.sdsInfo.storage_tech_env_risk,
        storage_incompatibility:
          state.pageReducer.sdsInfo.storage_incompatibility,
        storage_area: state.pageReducer.sdsInfo.storage_area,
        storage_requirements: state.pageReducer.sdsInfo.storage_requirements,
        storage_heat_and_ignition_sources:
          state.pageReducer.sdsInfo.storage_heat_and_ignition_sources,
        storage_technical_conditions:
          state.pageReducer.sdsInfo.storage_technical_conditions,
        storage_packing_material:
          state.pageReducer.sdsInfo.storage_packing_material,
        storage_packing_material_to_avoid:
          state.pageReducer.sdsInfo.storage_packing_material_to_avoid,
        further_info_storage_conditions:
          state.pageReducer.sdsInfo.further_info_storage_conditions,
        storage_stability: state.pageReducer.sdsInfo.storage_stability,
        storage_other_precautions:
          state.pageReducer.sdsInfo.storage_other_precautions,
        storage_industrial_solutions:
          state.pageReducer.sdsInfo.storage_industrial_solutions,
        storage_rmm: state.pageReducer.sdsInfo.storage_rmm,
        storage_usage_precautions:
          state.pageReducer.sdsInfo.storage_usage_precautions,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'storageInfoForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(StorageInfoForm)
);
