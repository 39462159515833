import { Add, Delete } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import SelectPPEItemsPopup from 'components/popups/SelectPPEItemsPopup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { ProtectionTable } from './protection-table';

const ProtectionFields = ({
  fields,
  categories,
  popupTitle,
  languageCode,
}: {
  fields: any;
  categories: any;
  popupTitle: string;
  languageCode: any;
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['fields', 'sds_authoring']);
  const [openAddPopup, setOpenAddPopup] = React.useState(false);

  const handleSelectPPEs = (ppeItems: any) => {
    ppeItems.map((el: any) => {
      fields.push({
        id: el.id,
        long_desc: el.long_desc,
        short_desc: el.short_desc,
        link_ppe_icon: el.link_ppe_icon,
      });
    });
  };

  const selectPPEItemsPopupProps = {
    open: openAddPopup,
    onSubmit: (selectedItems: any) => {
      handleSelectPPEs(selectedItems);
      setOpenAddPopup(false);
    },
    onClose: () => setOpenAddPopup(false),
    selected: fields?.map((el: any) => el.id),
    ppeCategories: categories,
    label: popupTitle,
    languageCode: languageCode,
  };

  return (
    <div>
      {fields?.getAll() && !!fields.getAll()?.length && (
        <ProtectionTable fields={fields} />
      )}

      <div className={classes.addMoreWrapper}>
        <Button variant='outlined' onClick={() => setOpenAddPopup(true)}>
          {t('sds_authoring:add_ppe_icons')} <Add />
        </Button>
      </div>
      {openAddPopup && <SelectPPEItemsPopup {...selectPPEItemsPopupProps} />}
    </div>
  );
};

export default ProtectionFields;
