import {
  ClassNameMap,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import useStyles from '../styles';
import { useEffect, useState } from 'react';
import { multipleClasses } from 'utils/helper';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const DataToTablePresentation = ({
  data,
}: {
  data: object;
}) => {
  const classes: ClassNameMap = useStyles();
  const { t } = useTranslation(['sds_authoring']);
  const [columns, setColumns] = useState<Array<string>>([]);
  const [rowData, setRowData] = useState<Array<Array<string>>>([]);
  const [isReRender, setIsReRender] = useState<boolean>(false);
  const rawDataToMatrix = (rawData: any) => {
    let columns: Array<string> = [];
    let rowMap = new Map();
    for (let index = 0; index < rawData.length; index++) {
      const element = rawData[index];
      columns.push(element['tag']);
      const valueItemList = element['value'];
      for (let i = 0; i < valueItemList.length; i++) {
        const valueItem = valueItemList[i];
        if (rowMap.has(valueItem['default_literal'])) {
          rowMap.get(valueItem['default_literal']).push(valueItem['value']);
        } else {
          rowMap.set(valueItem['default_literal'], [valueItem['value']]);
        }
      }
    }
    let rowData: Array<Array<string>> = [];
    rowMap.forEach((value, key) => {
      while (value.length < rawData.length) {
        value.push('');
      }
      rowData.push([key, ...value]);
    });
    setColumns(columns);
    setRowData(rowData);
    setIsReRender(!isReRender);
  };

  const tableRender = () => {
    return (
      <Box>
        <Typography variant="h6" className={classes.title}>
          {t('sds_authoring:transport_information_table')}
        </Typography>
        <Card variant="outlined" style={{ padding: '20px' }}>
          <TableContainer className={classes.tableContainer}>
            <Table size="small" aria-label="" style={{ tableLayout: 'fixed' }}>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell></TableCell>
                  {columns.map((keys, index) => (
                    <TableCell className={classes.tableCell} key={index}>
                      {keys}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowData.map((rowDataList: Array<string>, rowIndex: number) => {
                  return (
                    <TableRow>
                      {rowDataList.map(
                        (cellData: string, cellIndex: number) => {
                          return (
                            <TableCell
                              key={cellIndex}
                              className={
                                cellIndex === 0
                                  ? multipleClasses([
                                      classes.tableHeader,
                                      classes.tableCell,
                                    ])
                                  : ''
                              }
                            >
                              {cellIndex !== 0 ? cellData : cellData}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    );
  };
  useEffect(() => {
    rawDataToMatrix(data);
  }, []);
  return tableRender();
};
export default DataToTablePresentation;
