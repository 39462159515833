import { Grid, Typography } from '@mui/material';
import { connect, useSelector } from 'react-redux';
import { Field, FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { getStep4Fields } from './fields';
import { CustomFormProps } from '../utils';
import { IsoIconsField } from '../common/IsoIconsFields';
import { get } from '../../../api';
import React from 'react';

const FirstAidForm = (props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>) => {
  const classes = useStyles();
  const { handleNext, dirty } = props;
  const translations = useSelector(
    (state: any) => state.pageReducer.translations
  );
  const form = useSelector((state: any) => state.form.firstAidForm);
  const [isoIcons, setIsoIcons] = React.useState([]);

  React.useEffect(() => {
    const getIsoIconsRequest = get('/sdsAuthoring/utils/isoIcons/', {
      type: 'E',
    });
    getIsoIconsRequest.then((response) => {
      if (response.status === 200) {
        setIsoIcons(response.data);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (dirty) {
      handleNext({
        ...form.values,
        first_aid_icons_input: form.values.first_aid_icons?.map((el: any) => el.id),
      });
    } else {
      handleNext(null);
    }
  };

  const sections = getStep4Fields();

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['4_header_first_aid']}
      </Typography>
      <form onSubmit={handleSubmit}>
        {sections.map((section, index) => {
          return (
            <OutlinedSection key={index} title={section.title}>
              {section.fields.map((field, idx) => {
                if (field.isFieldArray) {
                  return (
                    <Grid key={idx} item xs={field.xs}>
                      <FieldArray
                        name={field.name}
                        component={IsoIconsField}
                        availableIcons={isoIcons}
                      />
                    </Grid>
                  );
                }

                return (
                  <Grid key={idx} item xs={field.xs}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      options={field.options ?? null}
                      required={field.required ?? null}
                      valueField={field.valueField ?? null}
                      labelField={field.labelField ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        })}
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.firstAidForm?.values) {
    return {
      initialValues: {
        medical_attention_and_treatment_needed:
          state.pageReducer.sdsInfo.medical_attention_and_treatment_needed,
        first_aid_general_notes:
          state.pageReducer.sdsInfo.first_aid_general_notes,
        if_inhaled: state.pageReducer.sdsInfo.if_inhaled,
        skin_contact: state.pageReducer.sdsInfo.skin_contact,
        eyes_contact: state.pageReducer.sdsInfo.eyes_contact,
        if_swallowed: state.pageReducer.sdsInfo.if_swallowed,
        self_protection: state.pageReducer.sdsInfo.self_protection,
        symptoms: state.pageReducer.sdsInfo.symptoms,
        general_advice: state.pageReducer.sdsInfo.general_advice,
        note_to_physicians: state.pageReducer.sdsInfo.note_to_physicians,
        specific_treatment: state.pageReducer.sdsInfo.specific_treatment,
        first_aid_icons: state.pageReducer.sdsInfo.first_aid_icons,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'firstAidForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(FirstAidForm)
);
