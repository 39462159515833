import { connect, useSelector } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, Typography } from '@mui/material';
import OutlinedSection from '../outlined-section';
import useStyles from '../styles';
import SubmitButton from '../submit-btn';
import { getStep6Fields } from './fields';
import { CustomFormProps } from '../utils';

const AccidentalReleaseForm = (props: CustomFormProps & InjectedFormProps<{}, CustomFormProps>) => {
  const classes = useStyles();
  const { handleNext, dirty } = props;
  const translations = useSelector((state: any) => state.pageReducer.translations);
  const form = useSelector((state: any) => state.form.accidentalReleaseForm);

  const handleSubmit = () => {
    if (dirty) {
      handleNext({ ...form.values });
    } else {
      handleNext(null);
    }
  };

  const sections = getStep6Fields();

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">
        {translations.headers['6_header_accidental_release_measures']}
      </Typography>
      <form onSubmit={handleSubmit}>
        {sections.map((section, index) => {
          return (
            <OutlinedSection key={index} title={section.title}>
              {section.fields.map((field, idx) => {
                return (
                  <Grid key={idx} item xs={field.xs}>
                    <Field
                      validate={field.validate ?? null}
                      name={field.name}
                      component={field.component}
                      label={field.label}
                      multiline={field.multiline ?? null}
                      options={field.options ?? null}
                      required={field.required ?? null}
                      valueField={field.valueField ?? null}
                      labelField={field.labelField ?? null}
                      classes={{ root: classes.textInput }}
                    />
                  </Grid>
                );
              })}
            </OutlinedSection>
          );
        })}
        <SubmitButton handleSubmit={handleSubmit} />
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  if (state.pageReducer.sdsInfo && !state.form.accidentalReleaseForm?.values) {
    return {
      initialValues: {
        personal_precautions: state.pageReducer.sdsInfo.personal_precautions,
        personal_precautions_for_non_emergency_personnel:
          state.pageReducer.sdsInfo
            .personal_precautions_for_non_emergency_personnel,
        personal_precautions_for_emergency_responders:
          state.pageReducer.sdsInfo
            .personal_precautions_for_emergency_responders,
        environmental_precautions:
          state.pageReducer.sdsInfo.environmental_precautions,
        small_spill: state.pageReducer.sdsInfo.small_spill,
        large_spill: state.pageReducer.sdsInfo.large_spill,
        methods_for_cleaning_up:
          state.pageReducer.sdsInfo.methods_for_cleaning_up,
        reference_to_other_sections:
          state.pageReducer.sdsInfo.reference_to_other_sections,
      },
    };
  }
}

export default connect(mapStateToProps)(
  reduxForm<{}, CustomFormProps>({
    form: 'accidentalReleaseForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
  })(AccidentalReleaseForm)
);
